import { A, Checkbox, colors, Input, Text } from "@framerjs/fraction"
import * as React from "react"
import styled from "styled-components"

interface IInputFieldProps {
  name: string
  value: string
  disabled?: boolean
  placeholder?: string
  error?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  autoFocus?: boolean
}

export const InputField = ({ name, value, error, placeholder, disabled, onChange, autoFocus }: IInputFieldProps) => {
  return (
    <>
      <Input
        name={name}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        style={
          disabled
            ? { backgroundColor: "rgba(255, 255, 255, 0.15)", color: "rgba(255, 255, 255, 0.5)" }
            : { backgroundColor: "rgba(255, 255, 255, 0.15)", color: "#fff" }
        }
        error={!!error}
        autoFocus={autoFocus}
      />
      <FormError error={error} />
    </>
  )
}

interface ITermsAndConditionsFieldProps {
  checked: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  error?: string
}

export const TermsAndConditionsField = ({ checked, onChange, error }: ITermsAndConditionsFieldProps) => {
  const linkColor = (!error && colors.primary) || "inherit"

  return (
    <Checkbox size="small" name="terms" checked={checked} onChange={onChange} error={!!error}>
      I agree to the{" "}
      <A color={linkColor} href="https://www.framer.com/tos/" target="_blank" rel="noopener noreferrer">
        Terms of Service
      </A>{" "}
      and{" "}
      <A
        color={linkColor}
        href="https://www.framer.com/support/accounts/privacy-statement/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Statement
      </A>
    </Checkbox>
  )
}

interface INewsletterFieldProps {
  checked: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const NewsletterField = ({ checked, onChange }: INewsletterFieldProps) => {
  return (
    <Checkbox size="small" name="newsletter" checked={checked} onChange={onChange}>
      Send me updates via email. Unsubscribe any time
    </Checkbox>
  )
}

interface IFormErrorProps {
  error: string | undefined
}
export const FormError = ({ error }: IFormErrorProps) => {
  if (!error) return null
  return (
    <ErrorContainer>
      <Text size="xsmall" color={colors.danger}>
        {error}
      </Text>
    </ErrorContainer>
  )
}

const ErrorContainer = styled.div`
  & {
    margin-top: 5px;
  }
`
