import React, { createContext, useContext } from "react"
import Castle from "@castleio/castle-js"
import { CASTLE_PUBLISHABLE_API_KEY } from "./env"
import captureError from "./util/captureError"

const castle = Castle.configure({ pk: CASTLE_PUBLISHABLE_API_KEY })

const CastleContext = createContext<typeof castle | null>(null)

interface Props {
  children: React.ReactNode
}

export const CastleProvider: React.FC<Props> = ({ children }) => {
  return <CastleContext.Provider value={castle}>{children}</CastleContext.Provider>
}

export function useCastle() {
  const castle = useContext(CastleContext)

  const createToken = async (): Promise<string | undefined> => {
    if (!castle) {
      captureError("Castle is not initialized")
      return
    }
    try {
      const token = await castle.createRequestToken()
      return token
    } catch (error) {
      captureError(error)
      return
    }
  }

  return { createToken }
}
